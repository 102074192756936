import { Axios, AxiosError, isAxiosError } from 'axios';

export enum ApiEndpoint {
  PROJECT_CATEGORIES = '/project/categories',
  PROJECT_QUESTIONS = '/project/questions',
  PROJECT_INSPIRE = '/project/inspire',
  PROJECT_INSPIRE_SIMILAR = '/project/inspire/similar',
  PROJECT_REFINE = '/project/refine',
  PROJECT_BUILD = '/project/build',
  REGISTER_INTEREST = '/user/register-interest',
}

class _ApiService {
  
  private axios: Axios = new Axios({
    baseURL: process.env.REACT_APP_API_URL,
  });

  public async get<T>(endpoint: ApiEndpoint, params?: string[]): Promise<T | undefined> {
    try {
      const url = params && params.length ? `${endpoint}/${params.join('/')}` : endpoint;
      const headers = { 'Authorization': `Bearer ${this.getAccessToken()}` };
      const result = await this.axios.get<T>(url, { responseType: 'json', headers });
      try {
        return JSON.parse(result.data as string);
      } catch (err) {
        return result.data;
      }
    } catch(err) {
      const error = err as Error | AxiosError;
      if(!isAxiosError(error)){
        throw (err as Error).message;
      } else {
        throw (err as AxiosError).message;
      }
    }
  }

  public async post<Request, Response>(endpoint: ApiEndpoint, data?: Request): Promise<Response | undefined> {
    try {
      const headers = { 'Authorization': `Bearer ${this.getAccessToken()}`, 'Content-Type': 'application/json' };
      const result = await this.axios.post<Response>(endpoint, JSON.stringify(data), { headers });
      try {
        return JSON.parse(result.data as string);
      } catch (err) {
        return result.data;
      }
    } catch(err) {
      const error = err as Error | AxiosError;
      if(!isAxiosError(error)){
        throw (err as Error).message;
      } else {
        throw (err as AxiosError).message;
      }
    }
  }

  private getAccessToken() {
    return localStorage.getItem('accessToken');
  }
}

export const ApiService = new _ApiService();
