import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { ApiEndpoint, ApiService } from "../service/api.service";

export function HomePage() {


  const [email, setEmail] = useState('');
  const [signingUp, setSigningUp] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [signedUp, setSignedUp] = useState(false);
  const [response, setResponse] = useState('');


  const handleSignUp = async () => {
    if (!email) {
      setErrorMessage('Email is required.');
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setErrorMessage('Please enter a valid email address.');
    } else {
      setErrorMessage('');
      setSigningUp(true);
      // const response: any = await ApiService.post(ApiEndpoint.REGISTER_INTEREST, { email: email })
      setTimeout(() => {
        setSignedUp(true);
        setSigningUp(false);
        // setResponse(response)
        setResponse('Thank you for signing up! We will keep you updated on our launch.')
      }, 2000);
    }
  };

  return (
    <section className="hero">
      <h1 className="hero-title">From Idea to Script, at Warp Speed</h1>
      <p className="hero-description">Need to write a screenplay at the speed of light? Look no further than ScriptSmithy! With this AI-powered platform, you'll be blasting through the writing process at warp speed. Don't let the complexities of screenwriting slow you down. Let ScriptSmithy be your trusty guide, as you navigate the vast expanse of your imagination to bring your story to life in record time.</p>
      <Link to="/project/new" className="btn btn-hero btn-pink">Start Writing &#10230;</Link>
      <div className="email-signup-section">
        {!signedUp ? (
          <>
            <div className="left">
              <h2>Full version coming soon</h2>
              <p>Sign up to stay updated and receive exclusive launch discounts</p>
            </div>
            <div className="signup-form">
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="johndoe@company.com"
                required
                disabled={signingUp}
              />
              <button
                onClick={handleSignUp}
                className={`${signingUp ? 'disabled' : ''} btn btn-blue`}
                disabled={signingUp}
              >
                {signingUp ? 'Signing Up...' : 'Sign Up'}
              </button>
            </div>
            <div className="right">
              <h3>3000+ writers are already on board</h3>
            </div>
            {errorMessage && <span style={{ color: 'red', display: 'block', margin:'12px 0' }}>{errorMessage}</span>}
          </>
        ) : (
          <div>
            {
              response ? <h2>{response}</h2> : <span>Something Went Wrong Please try again later.</span>
            }

          </div>
        )}
      </div>
    </section>
  );
}
